import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { ACTIVITY_TYPES } from 'state-domains/constants';
import { Row } from 'state-domains/domain';
import {
    buildDeleteCoordinatesRowUrl,
    buildDeleteCoordinatesRowPointsUrl,
} from 'state-domains/network/urls';
import { convertToCamel, sendDeleteRequestWithXSRFToken } from 'state-domains/utils';

export const deleteCoordinatesTableRow = (
    drillHoleId: string,
    rowReferenceId: string,
    type: ACTIVITY_TYPES,
): Observable<Row> => {
    const url =
        type === ACTIVITY_TYPES.DRILLING
            ? buildDeleteCoordinatesRowUrl(drillHoleId, rowReferenceId)
            : buildDeleteCoordinatesRowPointsUrl(drillHoleId, rowReferenceId);
    return sendDeleteRequestWithXSRFToken(url).pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const converted = convertToCamel<Row>(response);
            return observableOf(converted);
        }),
    );
};
